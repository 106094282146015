import React from 'react';

import { Content } from "rsuite"
import { Icon } from "rsuite"
import apps from './_people.js';

class People extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var active = this.props.id
    var app = apps[active]
    return (
      <Content className="content privacy">
        <h1>People</h1>
        <hr/>
        <div className="row">
          <div style={{"flex-basis": "300px"}}>
            <ul className="list">
            {
              Object.keys(apps).map((id) => {
                var app = apps[id]
                console.log(id, active)
                return (
                  <li key={id} active={(id == active).toString()}>
                    <a href={`/${id}`}>{app.title}</a>
                  </li>
                )
              })
            }
            </ul>
          </div>
          <div className="service">
            <h1>{app.title} {app.description}</h1>
            <div className="row">
              <div className="person-image">
                <img src={app.image}/>
              </div>
              <div className="person-info">
                { (app.vitae !== undefined) ? <span><br/><a href={app.vitae}><Icon icon="file"/> Curriculum Vitae</a></span> : (null) }
                { (app.telephone !== undefined) ? <span><br/><b><Icon icon="phone"/> Phone:</b> {app.telephone}</span> : (null) }
                { (app.mobile !== undefined) ? <span><br/><b><Icon icon="mobile"/> Mobile:</b> {app.mobile}</span> : (null) }
              </div>
            </div>
          </div>
        </div>
      </Content>
    )
  }
}

export default People;
