import React from 'react';

import { Content } from "rsuite"
import { Icon } from "rsuite"

class Offices extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Content className="content privacy">
        <h1>Offices</h1>
        <h2>Let us come to you. At Legal Words we work with clients around the world. We can send our people to you, operate over the Internet, or from any of our offices.</h2>
        <hr/>
        <h2>Brisbane Office</h2>
        <div className="row">
          <div>
            <img src="Board room.jpg"/>
          </div>
          <div>
            <p>
              27 Coutts Street<br/>
              Bulimba QLD 4171<br/>
              Australia
            </p>
            <p>
              <Icon icon="phone"/> +61 7 33994010
            </p>
          </div>
        </div>
      </Content>
    )
  }
}

export default Offices;
