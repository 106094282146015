import React from 'react';

import { Content } from "rsuite"
import { Icon } from "rsuite"
import apps from './data.js';

class Services extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var active = this.props.id
    var app = apps[active]
    return (
      <Content className="content privacy">
        <h1>Services</h1>
        <hr/>
        <div className="row">
          <div style={{"flex-basis": "300px"}}>
            <ul className="list">
            {
              Object.keys(apps).map((id) => {
                var app = apps[id]
                console.log(id, active)
                return (
                  <li key={id} active={(id == active).toString()}>
                    <a href={`/${id}`}>{app.title}</a>
                  </li>
                )
              })
            }
            </ul>
          </div>
          <div className="service">
            <h1>{app.title}</h1>
            <p>{app.description}</p>
            <img src={app.image}/>
          </div>
        </div>
      </Content>
    )
  }
}

export default Services;
