import React from 'react';

import { Content } from "rsuite"
import { Icon } from "rsuite"

class AboutUs extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Content className="content privacy">
        <h1>About Legal Words</h1>
        <hr/>
        <div className="row">
          <div>
            <img src="About us.jpg"/>
          </div>
          <div>
            <h2>Supporting success</h2>
            <p>
              <>At Legal Words we work with our clients in <i>supporting success</i>.
              The Legal Words team have extensive consulting experience in numerous industries including:</>
              <ul>
              <li>Tertiary education</li>
              <li>Secondary Education</li>
              <li>Continuing Professional Development</li>
              <li>Legal professions - Solicitors firms, the Judiciary and State Government</li>
              <li>Medical professions</li>
              </ul>
              <>The Legal Words team experience in international consulting in countries such as Vietnam, Turkey and Saudi Arabia.
              Members of the Legal Words team have completed projects for the World Bank, AusAid, and the Asian Development Bank. We look forward to supporting your business to achieve the sucess it deserves in a competitive international market place.
              </>
            </p>
            <p>
            Legal Words was formed to service the needs of both business and institutional clients wanting high quality service in the following areas:
            <ul>
              <li><b>Business Process Evaluation</b> - Administration and Information Technology farmeworks and workflows, Business plans, strategic analysis, customer satisfaction, and customer and staff voice surveys.</li>
              <li><b>Educational Consulting</b> - Continuing Professional Development (CPD), Accreditation, and International projects.</li>
              <li><b>Quality Assurance and Program Evaluation</b> - Quality Assurance audits, qualification frameworks, KPI's and Program Evaluation.</li>
              <li><b>Quantitative and Qualitative Research</b> - Social Science research and regulatory analysis.</li>
            </ul>
          </p>
          </div>
        </div>
      </Content>
    )
  }
}

export default AboutUs;
