export default {
  "larry-smith": {
    "title": "Professor Larry Smith",
    "description": "(Deceased 2016)",
    "telephone": "+61 428232410",
    "image": "Larrry Smith Photo.jpg",
    "vitae": "Larry.pdf"
  },
  "dale-pole": {
    "title": "Dr Dale Poel",
    "description": undefined,
    "telephone": "+1 (902) 477-2912",
    "image": "Dale.jpg",
    "vitae": "Dale.pdf"
  },
  "stephen-colbran": {
    "title": "Professor Stephen Colbran",
    "description": undefined,
    "telephone": "+61 7 3399 4010",
    "mobile": "0409 305 235",
    "image": "Stephen Colbran photo.jpg",
    "vitae": "Stephen.pdf"
  }
}
