export default {
  "consulting": {
    "title": "Educational Consulting",
    "description": "Continuing Professional Development (CPD), Accreditation, and International projects.",
    "image": "h-0.png"
  },
  "evaluation": {
    "title": "Business Process Evaluation",
    "description": "Administration and Information Technology farmeworks and workflows, Business plans, strategic analysis, customer satisfaction, and customer and staff voice surveys.",
    "image": "h-1.png"
  },
  "research": {
    "title": "Qualitative and Quantitative Research",
    "description": "Social Science research and regulatory analysis.",
    "image": "h-3.png"
  },
  "qa": {
    "title": "Quality Assurance and Program Evaluation",
    "description": "Quality Assurance audits, qualification frameworks, KPI's and Program Evaluation.",
    "image": "h-2.png"
  }
}
