import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

// rsuite
import { Container, Header, Content, Footer } from "rsuite"
import { Navbar, Nav, Icon } from "rsuite"
import { Grid, Row, Col } from 'rsuite';

import AboutUs from './AboutUs.js';
import Services from './Services.js';
import People from './People.js';
import Offices from './Offices.js';
import Support from './Support/Support.js';

import 'rsuite/dist/styles/rsuite-default.css'
import './App.css';
import apps from './data.js';
import people from './_people.js';

class HeroBlock extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var id = this.props.id
    var app = apps[id]
    return (
      <Content className="hero-block">
        <a href={`/${id}`}>
          <h1>{app["title"]}</h1>
          <h2>{app["description"]}</h2>
          <img src={app["image"]}/>
        </a>
      </Content>
    )
  }
}

class Hero extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="hero">
        <h1>Our mission is to support our clients in achieving success</h1>
        <h2>We assist our clients by thorough evidenced based research, sound project management, and timely well reasoned advice</h2>
        <br/>
        <Grid>
          <Row>
            <Col xs={12} xsPush={12}>
              <HeroBlock id="consulting"/>
            </Col>
            <Col xs={12} xsPull={12}>
              <HeroBlock id="evaluation"/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} xsPush={12}>
              <HeroBlock id="research"/>
            </Col>
            <Col xs={12} xsPull={12}>
              <HeroBlock id="qa"/>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

class Main extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Container>
        <Header>
          <Navbar className="content">
            <Navbar.Header>
              <div className="navbar-brand logo">
                <a className="app-logo-bar" href="/">
                  <span className="app-logo">
                    <img src="/logo-128.png" draggable="false"/>
                  </span>
                  <span className="app-title">
                    <h1>Legal Words</h1>
                    <h2>Supporting Success</h2>
                  </span>
                </a>
              </div>
            </Navbar.Header>
            <Nav pullRight>
              <Nav.Item href="/about" componentClass="a" icon={<Icon icon="star" />}>About Us</Nav.Item>
              <Nav.Item href={`/${Object.keys(apps)[0]}`} componentClass="a" icon={<Icon icon="check-circle" />}>Services</Nav.Item>
              <Nav.Item href="/offices" componentClass="a" icon={<Icon icon="building" />}>Offices</Nav.Item>
              <Nav.Item href={`/${Object.keys(people)[0]}`} componentClass="a" icon={<Icon icon="user" />}>People</Nav.Item>
              <Nav.Item href="/contact" componentClass="a" icon={<Icon icon="question-circle" />}>Support</Nav.Item>
            </Nav>
          </Navbar>
        </Header>
        <Switch>
          <Route exact path="/about" component={AboutUs}/>
          {
            Object.keys(apps).map((id)=>{
              return (
                <Route exact key={id} path={`/${id}`} render={(props) => (
                  <Services {...props} id={id} />
                )}/>
              )
            })
          }
          {
            Object.keys(people).map((id)=>{
              return (
                <Route exact key={id} path={`/${id}`} render={(props) => (
                  <People {...props} id={id} />
                )}/>
              )
            })
          }
          <Route exact path="/contact*" component={Support}/>
          <Route exact path="/offices*" component={Offices}/>
          <Route exact path="*" component={Hero}></Route>
        </Switch>

        {/*<AppInfo app="folder-forge"/>*/}
        <Footer>
        <br/>
        <h4>© Copyright 2016, <i>Legal Words Pty Ltd</i>, <b>ACN 075 351 521</b></h4>
        </Footer>
      </Container>
    )
  }
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Main/>
      </div>
    </BrowserRouter>
  );
}

export default App;
